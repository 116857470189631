import React from 'react';
import ProjectCard from './ProjectCard';
import {
  rentberry,
  pets4home,
  j1explorers,
  speedlead,
  texitcoin,
  tomb,
} from '../assets/constants';

import {
  ProjectsContainer,
  Container,
  ProjectOne,
  ProjectTwo,
  ProjectThree,
  ProjectFour,
  ProjectFive,
  ProjectSix,
  ProjectSeven,
  ProjectEight,
  Header,
} from './Projects.styles';

const Projects = ({ projects, largeScreen }) => {
  const xVarNegative = largeScreen ? -150 : -55;
  const xVarPositive = largeScreen ? 150 : 55;

  return (
    <ProjectsContainer ref={projects}>
      <Header>Projects</Header>
      <Container>
        <ProjectOne
          initial={{
            opacity: 0,
            x: xVarNegative,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={pets4home.nameText}
            nameTextExpanded={pets4home.nameTextExpanded}
            img={pets4home.image}
            gameplayImage={pets4home.gameplayImage}
            gameplayImageTwo={pets4home.gameplayImageTwo}
            description={pets4home.description}
            fullDescription={pets4home.fullDescription}
            icons={pets4home.icons}
            iconsLarge={pets4home.iconsLarge}
            link={pets4home.link}
            liveLink={pets4home.liveLink}
            colorSplash={pets4home.colorSplash}
            colorSplashWidth={'130px'}
            status={pets4home.status}
            devTypeOneofTwo={pets4home.devTypeOneofTwo}
            devTypeTwoofTwo={pets4home.devTypeTwoofTwo}
          />
        </ProjectOne>
        <ProjectTwo
          initial={{
            opacity: 0,
            x: xVarPositive,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={rentberry.nameText}
            nameTextExpanded={rentberry.nameTextExpanded}
            img={rentberry.image}
            gameplayImage={rentberry.gameplayImage}
            gameplayImageTwo={rentberry.gameplayImageTwo}
            description={rentberry.description}
            fullDescription={rentberry.fullDescription}
            icons={rentberry.icons}
            iconsLarge={rentberry.iconsLarge}
            link={rentberry.link}
            liveLink={rentberry.liveLink}
            colorSplash={rentberry.colorSplash}
            status={rentberry.status}
            colorSplashWidth={'104px'}
            margin={'none'}
            type={'wide'}
            devTypeOneofTwo={rentberry.devTypeOneofTwo}
            devTypeTwoofTwo={rentberry.devTypeTwoofTwo}
          />
        </ProjectTwo>
        <ProjectThree
          initial={{
            opacity: 0,
            x: xVarNegative,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={j1explorers.nameText}
            nameTextExpanded={j1explorers.nameTextExpanded}
            img={j1explorers.image}
            gameplayImage={j1explorers.gameplayImage}
            gameplayImageTwo={j1explorers.gameplayImageTwo}
            description={j1explorers.description}
            fullDescription={j1explorers.fullDescription}
            icons={j1explorers.icons}
            iconsLarge={j1explorers.iconsLarge}
            link={j1explorers.link}
            liveLink={j1explorers.liveLink}
            colorSplash={j1explorers.colorSplash}
            colorSplashWidth={'120px'}
            margin={'none'}
            status={j1explorers.status}
            devTypeOneofTwo={j1explorers.devTypeOneofTwo}
            devTypeTwoofTwo={j1explorers.devTypeTwoofTwo}
          />
        </ProjectThree>
        <ProjectFour
          initial={{
            opacity: 0,
            x: xVarPositive,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={speedlead.nameText}
            nameTextExpanded={speedlead.nameTextExpanded}
            img={speedlead.image}
            gameplayImage={speedlead.gameplayImage}
            gameplayImageTwo={speedlead.gameplayImageTwo}
            description={speedlead.description}
            fullDescription={speedlead.fullDescription}
            icons={speedlead.icons}
            iconsLarge={speedlead.iconsLarge}
            link={speedlead.link}
            liveLink={speedlead.liveLink}
            colorSplash={speedlead.colorSplash}
            colorSplashWidth={'110px'}
            margin={'none'}
            status={speedlead.status}
            devTypeOneofTwo={speedlead.devTypeOneofTwo}
            devTypeTwoofTwo={speedlead.devTypeTwoofTwo}
          />
        </ProjectFour>
        {/*<ProjectFive
          initial={{
            opacity: 0,
            x: xVarNegative,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={tomb.nameText}
            nameTextExpanded={tomb.nameTextExpanded}
            img={tomb.image}
            gameplayImage={tomb.gameplayImage}
            gameplayImageTwo={tomb.gameplayImageTwo}
            description={tomb.description}
            fullDescription={tomb.fullDescription}
            icons={tomb.icons}
            iconsLarge={tomb.iconsLarge}
            link={tomb.link}
            liveLink={tomb.liveLink}
            colorSplash={tomb.colorSplash}
            colorSplashWidth={'140px'}
            margin={'none'}
            status={tomb.status}
            devTypeOneofTwo={tomb.devTypeOneofTwo}
            devTypeTwoofTwo={tomb.devTypeTwoofTwo}
          />
        </ProjectFive>*/}
        <ProjectSix
          initial={{
            opacity: 0,
            x: xVarPositive,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={texitcoin.nameText}
            nameTextExpanded={texitcoin.nameTextExpanded}
            img={texitcoin.image}
            gameplayImage={texitcoin.gameplayImage}
            gameplayImageTwo={texitcoin.gameplayImageTwo}
            description={texitcoin.description}
            fullDescription={texitcoin.fullDescription}
            icons={texitcoin.icons}
            iconsLarge={texitcoin.iconsLarge}
            link={texitcoin.link}
            liveLink={texitcoin.liveLink}
            colorSplash={texitcoin.colorSplash}
            colorSplashWidth={'100px'}
            type={'wide'}
            status={texitcoin.status}
            devTypeOneofTwo={texitcoin.devTypeOneofTwo}
            devTypeTwoofTwo={texitcoin.devTypeTwoofTwo}
          />
        </ProjectSix>
        <ProjectSeven
          initial={{
            opacity: 0,
            x: xVarNegative,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={tomb.nameText}
            nameTextExpanded={tomb.nameTextExpanded}
            img={tomb.image}
            gameplayImage={tomb.gameplayImage}
            gameplayImageTwo={tomb.gameplayImageTwo}
            description={tomb.description}
            fullDescription={tomb.fullDescription}
            icons={tomb.icons}
            iconsLarge={tomb.iconsLarge}
            link={tomb.link}
            liveLink={tomb.liveLink}
            colorSplash={tomb.colorSplash}
            colorSplashWidth={'140px'}
            margin={'none'}
            status={tomb.status}
            devTypeOneofTwo={tomb.devTypeOneofTwo}
            devTypeTwoofTwo={tomb.devTypeTwoofTwo}
          />
        </ProjectSeven>
        {/*
        <ProjectEight
          initial={{
            opacity: 0,
            x: xVarPositive,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ProjectCard
            largeScreen={largeScreen}
            nameText={geoDash.nameText}
            nameTextExpanded={geoDash.nameTextExpanded}
            img={geoDash.image}
            gameplayImage={geoDash.gameplayImage}
            gameplayImageTwo={geoDash.gameplayImageTwo}
            description={geoDash.description}
            fullDescription={geoDash.fullDescription}
            icons={geoDash.icons}
            iconsLarge={geoDash.iconsLarge}
            link={geoDash.link}
            liveLink={geoDash.liveLink}
            colorSplash={geoDash.colorSplash}
            colorSplashWidth={'155px'}
            status={geoDash.status}
            devTypeOneofTwo={geoDash.devTypeOneofTwo}
            devTypeTwoofTwo={geoDash.devTypeTwoofTwo}
          />
        </ProjectEight>*/}
      </Container>
    </ProjectsContainer>
  );
};

export default Projects;
