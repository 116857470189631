import React from 'react';
import Main from './components/Main.js';
import { Route, Routes } from 'react-router';
import ViewPage from './components/views/ViewPage.js';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/Rentberry" element={<ViewPage />} />
        <Route path="/Pets4Homes" element={<ViewPage />} />
        <Route path="/TEXITcoin" element={<ViewPage />} />
        <Route path="/J1Explorers" element={<ViewPage />} />
        <Route path="/TombFinance" element={<ViewPage />} />
        <Route path="/Speedlead" element={<ViewPage />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </>
  );
}

export default App;
