import React from 'react';
import {
  BodyContainer,
  IconContainer,
  HeroHeader,
  UpperRow,
  UpperInnerDiv,
  LowerDiv,
  ChatDiv,
  TalkText,
  ChatArrow,
  CenteredDiv,
  BorderBlinkDiv,
} from './Home.styles.js';
import BorderBlink from '../assets/anim/borderBlink/BorderBlink.js';
import DevDesktop from '../assets/anim/devDesktop/DevDesktop.js';

const Home = ({ home, largeScreen }) => {
  const width = largeScreen ? '500px' : '225px';

  return (
    <BodyContainer>
      <IconContainer ref={home}>
        <UpperRow>
          <UpperInnerDiv>
            <BorderBlinkDiv width={width}>
              <BorderBlink />
            </BorderBlinkDiv>
            <HeroHeader>
              <ChatDiv>
                <ChatArrow />
                <TalkText>
                  I'm Luka,
                  <br />
                  A full stack developer with 4 years of experience.
                  <br />
                  ✔️Experienced in modern JavaScript frameworks and libraries for
                  building dynamic and responsive web applications.
                  <br />
                  ✔️Proficient in server-side technologies and frameworks for
                  developing robust and scalable applications.
                  <br />
                  ✔️Skilled in creating cross-platform mobile applications.
                  <br />
                  ✔️Knowledgeable in various database management systems for
                  efficient in data storage and retrieval.
                  <br />
                  ✔️Familiar with containerization and cloud platforms for deploying
                  and managing applications.
                  <br />
                  ✔️Proficient in using Jira for project management and collaboration
                </TalkText>
              </ChatDiv>
            </HeroHeader>
          </UpperInnerDiv>
        </UpperRow>
        <LowerDiv>
          <CenteredDiv>
            <DevDesktop />
          </CenteredDiv>
        </LowerDiv>
      </IconContainer>
    </BodyContainer>
  );
};

export default Home;
