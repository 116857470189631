import React from 'react';

import {
  ExperienceContainer,
  Header,
  Smalltext,
  TimeLine,
  Outer,
  Card,
  Info,
  Title,
  JobTitle,
} from './Work.styles.js';

const Work = ({ work }) => {
  const Timeline = () => {
    return (
      <TimeLine>
        <Header>Experience</Header>
        <Outer>
          <Card>
            <Info>
              <Title>Spiria</Title>
              <JobTitle>Full Stack Engineer</JobTitle>
              <Smalltext>Sep 2022 - Jul 2024</Smalltext>
              <ul>
                <li>
                  Worked as a full stack engineer at Spiria, contributing to the
                  development of J1Explorers, a tour app built using React, Node,
                  TypeScript, MySQL, and TailwindCSS.
                </li>
                <li>
                  Developed and maintained front-end components for J1Explorers,
                  ensuring a seamless and responsive user experience with
                  TailwindCSS.
                </li>
                <li>
                  Implemented back-end services and APIs for J1Explorers using Node
                  and MySQL, providing efficient data management and processing.
                </li>
                <li>
                  Collaborated with cross-functional teams to design and develop new
                  features, enhancing the overall functionality of J1Explorers.
                </li>
                <li>
                  Played a key role in the development of SpeedLead.click, utilizing
                  React 16, Laravel, Sass, Ant Design, MySQL, and CRM systems.
                </li>
                <li>
                  Developed user-friendly interfaces for SpeedLead.click using Ant
                  Design, ensuring a consistent and intuitive user experience.
                </li>
                <li>
                  Implemented back-end logic and database management for
                  SpeedLead.click with Laravel and MySQL, ensuring data integrity and
                  performance.
                </li>
                <li>
                  Worked closely with project managers and designers to deliver
                  high-quality, scalable solutions for both J1Explorers and
                  SpeedLead.click.
                </li>
              </ul>
            </Info>
          </Card>
          <Card>
            <Info>
              <Title>Codetribe</Title>
              <JobTitle>Full Stack Developer</JobTitle>
              <Smalltext>Jan 2021 - Aug 2022</Smalltext>
              <ul>
                <li>
                  Worked as a full stack developer at Codetribe, contributing to the
                  development of Tomb Finance using React, enhancing the platform's
                  user experience and interface.
                </li>
                <li>
                  Played a key role in the development of Texitcoin, utilizing React
                  16, Apollo, TypeGraphQL, GraphQL, Prisma, and Postgres to create a
                  robust and scalable application.
                </li>
                <li>
                  Implemented complex front-end features for Tomb Finance, ensuring a
                  seamless and responsive user interface.
                </li>
                <li>
                  Developed and maintained back-end services for Texitcoin,
                  integrating various technologies to provide efficient and reliable
                  data management.
                </li>
                <li>
                  Collaborated with cross-functional teams to design and implement
                  new features, improving the overall functionality and performance
                  of both Tomb Finance and Texitcoin.
                </li>
              </ul>
            </Info>
          </Card>
          <Card>
            <Info>
              <Title>TechWings</Title>
              <JobTitle>Frontend Developer</JobTitle>
              <Smalltext>Jun 2020 - Dec 2020</Smalltext>
              <ul>
                <li>
                  Participated in the development of Pets4Homes.co.uk as an intern,
                  working with Next.js 9 to enhance the platform's performance and
                  user experience.
                </li>
                <li>
                  Contributed to the front-end development of Rentberry.com using
                  Angular 10, implementing new features and ensuring high-quality
                  code.
                </li>
                <li>
                  Assisted in optimizing the user interface of Pets4Homes.co.uk,
                  improving site responsiveness and load times.
                </li>
                <li>
                  Collaborated with the development team on Rentberry.com to
                  integrate new modules and maintain existing components, enhancing
                  overall functionality.
                </li>
                <li>
                  Gained valuable experience in modern web development frameworks,
                  working on diverse projects with Next.js and Angular.
                </li>
              </ul>
            </Info>
          </Card>
        </Outer>
      </TimeLine>
    );
  };

  return (
    <>
      <ExperienceContainer ref={work}>
        <Timeline />
      </ExperienceContainer>
    </>
  );
};

export default Work;
