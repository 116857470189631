import rentberry1 from './img/rentberry1.com.png';
import rentberry2 from './img/rentberry2.com.png';
import rentberry3 from './img/rentberry3.com.png';

import pets4home1 from './img/pets4home1.png';
import pets4home2 from './img/pets4home2.png';
import pets4home3 from './img/pets4home3.png';

import j1explorers1 from './img/j1explorers1.png';
import j1explorers2 from './img/j1explorers2.png';
import j1explorers3 from './img/j1explorers3.png';

import speedlead1 from './img/speedlead1.png';
import speedlead2 from './img/speedlead2.png';
import speedlead3 from './img/speedlead3.png';

import texitcoin1 from './img/texitcoin1.png';
import texitcoin2 from './img/texitcoin2.png';
import texitcoin3 from './img/texitcoin3.png';

import tomb1 from './img/tomb1.png';
import tomb2 from './img/tomb2.png';
import tomb3 from './img/tomb3.png';

import apollo from './img/apollo-graphql-1.svg';
import graphql from './img/graphql-logo-2.svg';
import prisma from './img/prisma-svgrepo-com.svg';

import {
  UnityIcon,
  CSharpIcon,
  ReactIconColor,
  TypescriptIcon,
  JavascriptIcon,
  StorybookIcon,
  ExpressIcon,
  PostregSQLIcon,
  NodeJSIcon,
  PythonIcon,
  DjangoIcon,
  NextJSIcon,
  AngularIcon,
  LaravelIcon,
  SassIcon,
  ApolloIcon,
  GraphQLIcon,
  PrismaIcon,
  Web3Icon,
} from './icons';

export const softwareDev = 'red';

export const frontendDev = 'purple';

export const gameDev = '#0703d0';

export const mobileDev = '#00DDFF';

export const verdigras = '#3CBBB1';

export const silver = '#C4CBCA';

export const night = '#0A0F0D';

export const russionViolet = '#2A1E5C';

export const crayolaRed = '#EE4266';

export const brightPurple = '#7510f7';

export const teal = '#5be9b9';

export const darkPurple = '#141c3a';

export const rentberry = {
  nameText: 'Rentberry',
  nameTextExpanded: 'Transparent and secure home rental platform',
  colorSplash: frontendDev,
  image: rentberry1,
  gameplayImage: rentberry2,
  gameplayImageTwo: rentberry3,
  description:
    'Transparent and secure home rental platform that connects tenants and landlords',
  fullDescription:
    'This was created using Angular and Express. Rentberry is a rental platform that streamlines the process of finding and applying for rental properties through an online system. It allows potential tenants to submit applications directly to landlords, making it easier to manage rental listings and offers features such as background checks, automated lease agreements, and payment processing',
  link: null,
  liveLink: 'https://rentberry.com',
  devTypeOneofTwo: 'Web',
  devTypeTwoofTwo: 'Development',
  status: 'Live',
  icons: [
    <AngularIcon width={30} height={30} />,
    <TypescriptIcon width={30} height={30} />,
  ],
  iconsLarge: [
    <AngularIcon width={40} height={40} />,
    <TypescriptIcon width={40} height={40} />,
  ],
};

export const pets4home = {
  nameText: 'Pets4Homes',
  nameTextExpanded: "UK's largest free online pet marketplace",
  colorSplash: frontendDev,
  image: pets4home1,
  gameplayImage: pets4home2,
  gameplayImageTwo: pets4home3,
  description:
    'UK’s largest online pet marketplace, relied upon by up to 7 million people monthly',
  fullDescription:
    'This was created using NextJS and Node.js. Pets4Homes is a commercial pet advertising website where individuals can buy and sell pets, rather than adopting them. The platform provides listings for various types of pets from different owners.',
  link: null,
  liveLink: 'https://pets4homes.co.uk',
  devTypeOneofTwo: 'Web',
  devTypeTwoofTwo: 'Development',
  status: 'Live',
  icons: [
    <NextJSIcon width={30} height={30} />,
    <TypescriptIcon width={30} height={30} />,
  ],
  iconsLarge: [
    <NextJSIcon width={40} height={40} />,
    <TypescriptIcon width={40} height={40} />,
  ],
};

export const j1explorers = {
  nameText: 'J1Explorers',
  nameTextExpanded: 'A customizable tour specifically for student visa employees',
  colorSplash: frontendDev,
  image: j1explorers1,
  gameplayImage: j1explorers2,
  gameplayImageTwo: j1explorers3,
  description:
    'A customizable tour specifically for student visa employees– Visit LA, Las Vegas, and NYC',
  fullDescription:
    'This was created using React and Express.js. The J-1 program is designed for international exchange and enables participants to gain experience in the U.S. while contributing to cultural exchange',
  link: null,
  liveLink: 'https://j1explorers.com',
  devTypeOneofTwo: 'Web',
  devTypeTwoofTwo: 'Development',
  status: 'Live',
  icons: [
    <ReactIconColor width={30} height={30} />,
    <ExpressIcon width={33} height={30} />,
    <NodeJSIcon width={33} height={30} />,
    <TypescriptIcon width={30} height={30} />,
  ],
  iconsLarge: [
    <ReactIconColor width={40} height={40} />,
    <ExpressIcon width={44} height={40} />,
    <NodeJSIcon width={44} height={40} />,
    <TypescriptIcon width={40} height={40} />,
  ],
};

export const speedlead = {
  nameText: 'Speedlead',
  nameTextExpanded: 'A highly customizable CRM system',
  colorSplash: frontendDev,
  image: speedlead1,
  gameplayImage: speedlead2,
  gameplayImageTwo: speedlead3,
  description:
    'A highly customizable CRM system that allows users to tailor their sales pipeline and dashboard according to their preferences.',
  fullDescription:
    'This was created using Laravel and React. Speadlead is a highly customizable CRM system that allows users to tailor their sales pipeline and dashboard according to their preferences',
  link: null,
  liveLink: 'https://speedlead.click',
  devTypeOneofTwo: 'Web',
  devTypeTwoofTwo: 'Development',
  status: 'Live',
  icons: [
    <LaravelIcon width={30} height={30} />,
    <SassIcon width={30} height={30} />,
    <TypescriptIcon width={30} height={30} />,
  ],
  iconsLarge: [
    <LaravelIcon width={40} height={40} />,
    <SassIcon width={40} height={40} />,
    <TypescriptIcon width={40} height={40} />,
  ],
};

export const texitcoin = {
  nameText: 'TEXITcoin',
  nameTextExpanded: 'The mineable cryptocurrency driving Texas independence',
  colorSplash: frontendDev,
  image: texitcoin1,
  gameplayImage: texitcoin2,
  gameplayImageTwo: texitcoin3,
  description:
    'A cryptocurrency created for Texans, aiming to democratize finance and promote a fairer economic system',
  fullDescription:
    'This was created using React and GraphQL. More than a digital currency, TEXITcoin symbolizes the unwavering commitment of Texans to restore the values of true Americanism, even if it means breaking the bands with the federal government and other states to do so',
  link: null,
  liveLink: 'https://explorer.texitcoin.org',
  devTypeOneofTwo: 'Web',
  devTypeTwoofTwo: 'Development',
  status: 'Live',
  icons: [
    <ReactIconColor width={30} height={30} />,
    <img width={30} height={30} src={apollo} />,
    <img width={30} height={30} src={graphql} />,
    <img width={30} height={30} src={prisma} />,
  ],
  iconsLarge: [
    <ReactIconColor width={40} height={40} />,
    <img width={40} height={40} src={apollo} />,
    <img width={40} height={40} src={graphql} />,
    <img width={40} height={40} src={prisma} />,
  ],
};

export const tomb = {
  nameText: 'Tomb Finance',
  nameTextExpanded: 'The first algorithmic stablecoin on Fantom Opera',
  colorSplash: frontendDev,
  image: tomb1,
  gameplayImage: tomb2,
  gameplayImageTwo: tomb3,
  description:
    'Tomb Finance provides an algorithmic token that is pegged to the price of FTM',
  fullDescription:
    'This was created using React and TypeScript. Tomb Finance provides an algorithmic token that is pegged to the price of FTM. This mirrored token is believed to work as an alternative to FTM as a majority of FTM tokens are staked. Tomb Finance has recently grown drastically in TVL',
  link: null,
  liveLink: 'https://grave.tomb.com/',
  devTypeOneofTwo: 'Web',
  devTypeTwoofTwo: 'Development',
  status: 'Live',
  icons: [<ReactIconColor width={30} height={30} />],
  iconsLarge: [<ReactIconColor width={40} height={40} />],
};
