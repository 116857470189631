import React from 'react';
import {
  ReactIcon,
  MobileIcon,
  SoftwareIcon,
  UnityIcon2,
  Ptag,
  ClosingPtag,
} from '../assets/icons.js';
import {
  softwareDev,
  frontendDev,
  mobileDev,
  gameDev,
} from '../assets/constants.js';
import {
  SkillsContainer,
  Header,
  Skills0,
  SkillsHeader,
  ColorAccent,
  Icon,
  Title,
  DescriptionBody,
  Description,
  HTMLSnippet,
  Skills1,
  Skills2,
  Skills3,
  Container,
  VertLine,
} from './Skills.styles.js';

const Skills = ({ skills, largeScreen }) => {
  // const style = "linear-gradient(to left, #1a6dff,#c822ff);";
  // const style2 = "linear-gradient(to left, #c822ff,#6dc7ff);";
  return (
    <Container>
      <SkillsContainer>
        <Header ref={skills}>My Skills</Header>
        <Skills0>
          <SkillsHeader>
            <Icon>
              <SoftwareIcon height={60} width={60} />{' '}
            </Icon>
            <Title>
              <ColorAccent background={softwareDev} />
              Software <br />
              Development{' '}
            </Title>
          </SkillsHeader>
          <DescriptionBody>
            <HTMLSnippet>
              {' '}
              <Ptag letterFill={mobileDev} height={25} width={25} />{' '}
              <VertLine borderColor={mobileDev} height={'75px'} />
              <ClosingPtag letterFill={mobileDev} height={35} width={35} />{' '}
            </HTMLSnippet>
            <Description>
              Software Dev, functional and OOP programming, Javascript, Typescript,
              PHP and Python
            </Description>
          </DescriptionBody>
        </Skills0>
        <Skills1>
          <SkillsHeader>
            <Icon>
              <ReactIcon height={60} width={60} />{' '}
            </Icon>
            <Title>
              <ColorAccent background={frontendDev} width={'135px'} />
              Full Stack <br />
              Development{' '}
            </Title>
          </SkillsHeader>
          <DescriptionBody>
            <HTMLSnippet>
              <Ptag letterFill={mobileDev} height={25} width={25} />{' '}
              <VertLine borderColor={mobileDev} height={'60px'} />
              <ClosingPtag letterFill={mobileDev} height={35} width={35} />{' '}
            </HTMLSnippet>
            <Description>
              Node.js(JS/TS), React.js, Next.js, Vue.js, Express and Laravel.
            </Description>
          </DescriptionBody>
        </Skills1>
        <Skills2>
          <SkillsHeader>
            <Icon>
              <MobileIcon height={60} width={60} />{' '}
            </Icon>
            <Title>
              <ColorAccent background={mobileDev} width={'95px'} />
              Mobile <br />
              Development{' '}
            </Title>
          </SkillsHeader>
          <DescriptionBody>
            <HTMLSnippet>
              {' '}
              <Ptag letterFill={mobileDev} height={25} width={25} />{' '}
              <VertLine borderColor={mobileDev} />
              <ClosingPtag letterFill={mobileDev} height={35} width={35} />{' '}
            </HTMLSnippet>
            <Description>
              React Native mobile development for iOS, and Android
            </Description>
          </DescriptionBody>
        </Skills2>
      </SkillsContainer>
    </Container>
  );
};

export default Skills;
